* { 
  box-sizing: border-box;
}

html, body{
  height: 100%;
  min-height: 100%;
  width:  100%;
  position: relative;
  text-align: center;
  background:  #000;
  color:  #fff;
  padding: 0;
  margin:  0;
  z-index: 1;
  transform: translate3d(0,0,0);

  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.43;

  text-rendering: optimizeLegibility;
  font-feature-settings: 'pnum' on,'lnum' on;
  -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch!important;

  -moz-hyphens: none;
  -ms-hyphens: none;
  -webkit-hyphens: none;
  hyphens: none;
}

.text-content{
  font-family: "Times New Roman", Times, serif;
}

body, ul, ol, dl {
  margin: 0;
}

article, aside, audio, 
footer, header, nav, section, video {
  display: block; 
  }
  
  /* ensures that older browsers will display HTML5 elements correctly */
  
h1, h2, h3, h4, h5, h6, p, span, a{
  color:  #fff;
  margin:  0;
  padding:  0;
  /* mix-blend-mode: difference; */
  /* background-color:black; */
}

  :focus {
    outline: 0
}

::-webkit-input-placeholder {
    color: #8c8c8c
}

::-moz-placeholder {
    color: #8c8c8c
}

:-ms-input-placeholder {
    color: #8c8c8c
}

:-moz-placeholder {
    color: #8c8c8c
}
  
textarea { 
  resize: vertical;
}
 /* changes textarea resizing from "both" (UA default) to vertical only */
 
table { border-collapse: collapse; }
td {
  padding: .5rem;
}
  /* causes tables to actually look like tables */
  
img { 
  border: none;
  max-width: 100%;
}
  /* removes outlines from linked images (for older browsers and IE), sets up images for responsive design layout */
  
button {
    background: unset;
    border: unset;
    padding: 0;
    cursor: pointer
}

input[type="submit"]::-moz-focus-inner, 
  input[type="button"]::-moz-focus-inner {
    border : 0px;
  }
  /* removes the inner border effect from focused buttons for form elements in Firefox */
  
input[type="search"] { 
  -webkit-appearance: textfield;
} 
input[type="submit"] { 
  -webkit-appearance:none;
}
  /* removes the OS X appearance from HTML5 search inputs and submit buttons when viewed in Safari */
  
input:required:after {
  color: #f00;
  content: " *";
}
  /* sets up required form fields with the conventional following red asterix */
input[type="email"]:invalid { 
  background: #f00;
}
  /* causes a visual alert in browsers that do client-side checking of HTML5 email field – this may already be the default in some browsers. */
  
.right { 
  float: right;
  margin-left: 2rem;
  clear: right;
}
.left { 
  float: left;
  margin-right: 2rem;
  clear: left;
}
  /* allows a quick shortcut for floating elements left and right while avoiding the “float quirk” problem */
  
sub, sup { 
  line-height: 0;
}
    /* sets superscript and subscript text so that it doesn't throw off leading for elements in which they are included */

.visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px
}

.hide-scroll::-webkit-scrollbar {
    display: none
}

.hide-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none
}

.blend--difference {
    color: #fff;
    mix-blend-mode: difference
}

.align-left, .align-left > *{
  text-align: left;
}