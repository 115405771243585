.home-top{
	position: absolute;
	top: 0;
	width: 100%;
	margin-top: 10px;
}
.home-top-spacer{
	height: 100px;
}
.home-container{
	min-height: 100%;
	mix-blend-mode: difference;
}
.home-content{
	position: relative;
	min-height: 500px;
	margin-bottom: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.content-text{
	text-align: center;
	padding:  50px 20px;
}
.content-text > *{
	width: 100%;
	max-width: 600px;
}
.content-image > *{
	width: 100%;
}
.content-image > iframe{
	min-height: 50vh;
}
.content-text-title{
	font-size: 36px;
	line-height: 40px;
	margin-bottom: 20px;
}
.content-text-details{
	font-size: 16px;
	line-height: 1.4;
}
.content-text,.content-image{
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
}
.content-column{
	flex-flow: column;
}

@media(max-width: 900px){
	.content-text,.content-image{
		width: 100%;
	}
	.content-text-image{
		flex-flow: column;
	}
	.content-image-text{
		flex-flow: column-reverse;
	}
}

.home-bg-video{
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}
.home-logo{
	width: 100%;
	max-width: 500px;
}
.home-logo > div{
	margin-bottom:  10px;
}
.home-logo img{
	width: 100%;
	max-width: 500px;
	-webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .5));
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .5));
}
.home-mouse{
	position: absolute;
	bottom: 0;
}
.home-mouse-title{
	margin-bottom:  10px;
}
.home-mouse img{
	margin-top: 10px;
	max-width: 60px;
}